import { NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuardService} from './auth/services/auth-guard.service';
import {NewPicturesComponent} from './components/views-photos/new-pictures/new-pictures.component';
import {ContradictoryAuthGuardService} from './auth/services/contradictory-auth-guard.service';
import {DirectAccessGuardService} from './auth/services/direct-access-guard.service';
import {RedirectComponent} from './components/redirect/redirect.component';
import { ModalajoutcontactComponent } from './pages/bascontact/menu/modalajoutcontact/modalajoutcontact.component';

const routes: Routes = [

    {
        path :'ajouter' , component:ModalajoutcontactComponent
    },

    {
        path: '',
        redirectTo: 'home', //home
        pathMatch: 'full',
        canActivate: [AuthGuardService],
        
    }, 

    { path: 'my-profile', loadChildren: './pages/my-profile/my-profile.module#MyProfilePageModule', canActivate: [AuthGuardService], },


    {
        path: 'photos-list',
        loadChildren: './pages/photos-list/photos-list.module#PhotosListPageModule',
        canActivate: [AuthGuardService],
        data: {
            name: ['ROLE_ADMIN','ROLE_RH','ROLE_PRATICIEN','ROLE_ASSISTANTES','ROLE_FINANCIERS','ROLE_USER','ROLE_SECRETAIRES'],

        }
    },

    {
        path: 'viewer',
        loadChildren: './pages/viewer/viewer.module#ViewerPageModule',
        canActivate: [AuthGuardService, DirectAccessGuardService]
    },


    {
        path: 'home', loadChildren: './pages/accueil/accueil.module#AccueilPageModule',
        canActivate: [AuthGuardService]
    },


    {
        path: 'edit-photos-list',
        loadChildren: './pages/edit-photos-list/edit-photos-list.module#EditPhotosListPageModule',
        canActivate: [AuthGuardService, DirectAccessGuardService]
    },
    {
        path: 'baspremier', loadChildren: './pages/baspremier/baspremier.module#BaspremierModule',
        canActivate: [AuthGuardService],
        data: {
            name: ['ROLE_ADMIN','ROLE_RH','ROLE_PRATICIEN','ROLE_ASSISTANTES','ROLE_USER','ROLE_SECRETAIRES'],

        }
    },
    {
        path: 'login', loadChildren: './auth/login/login.module#LoginPageModule',
        canActivate: [ContradictoryAuthGuardService]
    },
    {
        path: 'picturesPatient', component: NewPicturesComponent,
        canActivate: [AuthGuardService, DirectAccessGuardService]
    },

    {
        path: 'redir', component: RedirectComponent,
        canActivate: [AuthGuardService]
    }, 



    { path: 'preanalyse', loadChildren: './pages/basdiag/preanalyse/preanalyse.module#PreanalysePageModule',
        canActivate: [AuthGuardService],
        
    },
    
    { path: 'masquefacial', loadChildren: './pages/basdiag/masquefacial/masquefacial.module#MasquefacialPageModule',
        canActivate: [AuthGuardService],
        
    },
    { path: 'facesourire', loadChildren: './pages/basdiag/facesourire/facesourire.module#FacesourirePageModule',
        canActivate: [AuthGuardService],
         
    },
    { path: 'occlusal', loadChildren: './pages/basdiag/occlusal/occlusal.module#OcclusalPageModule',
        canActivate: [AuthGuardService],
        
    },
    { path: 'fonctionnel', loadChildren: './pages/basdiag/fonctionnel/fonctionnel.module#FonctionnelPageModule',
        canActivate: [AuthGuardService],
        
    },
    { path: 'arcades', loadChildren: './pages/basdiag/arcades/arcades.module#ArcadesPageModule',
        canActivate: [AuthGuardService],
        
    },
    { path: 'sourire', loadChildren: './pages/basdiag/sourire/sourire.module#SourirePageModule',
        canActivate: [AuthGuardService],
        
    },
    { path: 'profil', loadChildren: './pages/basdiag/profil/profil.module#ProfilPageModule',
        canActivate: [AuthGuardService],
       
    },
    { path: 'radio', loadChildren: './pages/basdiag/radio/radio.module#RadioPageModule',
        canActivate: [AuthGuardService],
        
    },
    { path: 'pano', loadChildren: './pages/basdiag/pano/pano.module#PanoPageModule',
        canActivate: [AuthGuardService],
        
    },
    { path: 'basdiagadultehome', loadChildren: './pages/basdiag/basdiagadultehome/basdiagadultehome.module#BasdiagadultehomePageModule',
        canActivate: [AuthGuardService],
         
    },


    {
        path: 'basdiag', loadChildren: './pages/basdiag/basdiag.module#BasdiagPageModule',
        canActivate: [AuthGuardService],
        data: {
            name: ['ROLE_ADMIN','ROLE_PRATICIEN','ROLE_ASSISTANTES','ROLE_FINANCIERS'],

        }
    },

    { path: 'resultat', loadChildren: './pages/basdiag/resultat/resultat.module#ResultatPageModule',
        canActivate: [AuthGuardService] },
    { path: 'basdiag-mini', loadChildren: './pages/basdiag-mini/basdiag-mini.module#BasdiagMiniPageModule',
        canActivate: [AuthGuardService] },
    { path: 'preanalyse-mini', loadChildren: './pages/basdiag-mini/preanalyse-mini/preanalyse-mini.module#PreanalyseMiniPageModule',
        canActivate: [AuthGuardService] },
    { path: 'occlusal-mini', loadChildren: './pages/basdiag-mini/occlusal-mini/occlusal-mini.module#OcclusalMiniPageModule',
        canActivate: [AuthGuardService] },
    { path: 'arcades-mini', loadChildren: './pages/basdiag-mini/arcades-mini/arcades-mini.module#ArcadesMiniPageModule',
        canActivate: [AuthGuardService] },
    { path: 'radio-mini', loadChildren: './pages/basdiag-mini/radio-mini/radio-mini.module#RadioMiniPageModule',
        canActivate: [AuthGuardService] },
    { path: 'resultat-mini', loadChildren: './pages/basdiag-mini/resultat-mini/resultat-mini.module#ResultatMiniPageModule',
        canActivate: [AuthGuardService] },
    { path: 'parametrage', loadChildren: './pages/basdiag-mini/parametrage/parametrage.module#ParametragePageModule',
        canActivate: [AuthGuardService] },

    { path: 'basdiag-enfant-mini', loadChildren: './pages/basdiagEnfant-mini/basdiag-enfant-mini/basdiag-enfant-mini.module#BasdiagEnfantMiniPageModule',
        canActivate: [AuthGuardService] },
    { path: 'arcades-enfant-mini', loadChildren: './pages/basdiagEnfant-mini/arcades-mini/arcades-enfant-mini/arcades-enfant-mini.module#ArcadesEnfantMiniPageModule',
        canActivate: [AuthGuardService] },
    { path: 'occlusal-enfant-mini', loadChildren: './pages/basdiagEnfant-mini/occlusal-mini/occlusal-enfant-mini/occlusal-enfant-mini.module#OcclusalEnfantMiniPageModule',
        canActivate: [AuthGuardService] },
    { path: 'radio-enfant-mini', loadChildren: './pages/basdiagEnfant-mini/radio-mini/radio-enfant-mini/radio-enfant-mini.module#RadioEnfantMiniPageModule',
        canActivate: [AuthGuardService] },
    { path: 'resultat-enfant-mini', loadChildren: './pages/basdiagEnfant-mini/resultat-enfant-mini/resultat-enfant-mini.module#ResultatEnfantMiniPageModule',
        canActivate: [AuthGuardService] },

    { path: 'resultat-enfant', loadChildren: './pages/basdiagEnfant-mini/resultat-enfant/resultat-enfant.module#ResultatEnfantPageModule' },

    { path: 'liste-patients', loadChildren: './pages/liste-patients/liste-patients.module#ListePatientsPageModule' },

    { path: 'cephalometrie', loadChildren: './pages/cephalometrie/cephalometrie.module#CephalometriePageModule' },

    { path: 'recherche-patient', loadChildren: './pages/recherche-patient/recherche-patient.module#RecherchePatientPageModule' },

    { path: 'choix-bas-diag-mini', loadChildren: './pages/choix-bas-diag-mini/choix-bas-diag-mini.module#ChoixBasDiagMiniPageModule', canActivate: [AuthGuardService] },
    { path: 'choix-bas-diag', loadChildren: './pages/choix-bas-diag/choix-bas-diag.module#ChoixBasDiagPageModule' },
    { path: 'parametrage-basdiag', loadChildren: './pages/basdiag/parametrage-basdiag/parametrage-basdiag.module#ParametrageBasdiagPageModule' },

    { path: 'basdiag-enfant', loadChildren: './pages/basdiag-enfant/basdiag-enfant.module#BasdiagEnfantPageModule' },
    { path: 'masquefacial-enfant', loadChildren: './pages/basdiag-enfant/masquefacial-enfant/masquefacial-enfant.module#MasquefacialEnfantPageModule' },
    { path: 'facesourire-enfant', loadChildren: './pages/basdiag-enfant/facesourire-enfant/facesourire-enfant.module#FacesourireEnfantPageModule' },
    { path: 'occlusal-enfant', loadChildren: './pages/basdiag-enfant/occlusal-enfant/occlusal-enfant.module#OcclusalEnfantPageModule' },
    { path: 'fonctionnel-enfant', loadChildren: './pages/basdiag-enfant/fonctionnel-enfant/fonctionnel-enfant.module#FonctionnelEnfantPageModule' },
    { path: 'arcades-enfant', loadChildren: './pages/basdiag-enfant/arcades-enfant/arcades-enfant.module#ArcadesEnfantPageModule' },
    { path: 'sourire-enfant', loadChildren: './pages/basdiag-enfant/sourire-enfant/sourire-enfant.module#SourireEnfantPageModule' },
    { path: 'profil-enfant', loadChildren: './pages/basdiag-enfant/profil-enfant/profil-enfant.module#ProfilEnfantPageModule' },
    { path: 'radio-enfant', loadChildren: './pages/basdiag-enfant/radio-enfant/radio-enfant.module#RadioEnfantPageModule' },
    { path: 'resultat-enfant-full', loadChildren: './pages/basdiag-enfant/resultat-enfant/resultat-enfant.module#ResultatEnfantPageModule' },



  {
    path: '**',
    loadChildren: './pages/not-found/not-found.module#NotFoundPageModule',
    canActivate: [AuthGuardService]
    },




];

// branch dev test
@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
