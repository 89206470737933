// This file can be replaced during build by using the `f*ileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // production: true,
  // root_folder: 'https://media.basmile.com/',
  // // apiUrl: 'https://statics.basmile.com:8080/',
  // apiUrl: 'http://localhost:8080/',
  folder: 'basdiag/',
  cabinet: "basdiag",
  currency: 'CHF', 

  production: true,
  // root_folder: 'https://media-02.basmile.clt.secure4all.tech/',
  root_folder: 'https://media.basmile.clt.secure4all.tech/',
  
  // apiUrl: 'http://localhost:8080/',
  // apiUrl : 'https://recette-01.api.basmile.clt.secure4all.tech:48084/',
  // apiUrl: 'https://bandol.api.recette.basmile.clt.secure4all.tech/',
  // apiUrl: 'https://chantepoulet.api.prod.basmile.clt.secure4all.tech/',
  apiUrl: 'https://minibasdiag.api.prod.basmile.clt.secure4all.tech/',
  // apiUrl: 'http://212.106.125.52/',



  
  // apiUrl: 'http://212.106.125.45:8087/',
  
  // apiUrl: 'https://bergues.api.prod.basmile.clt.secure4all.tech/',
  // apiUrl: 'https://toulon.api.prod.basmile.clt.secure4all.tech/',
  

//  apiUrl: 'http://localhost:8080/',
  // folder: 'chantepoulet/',
  // cabinet: 'chantepoulet',
  // currency: 'CHF',

};
