import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {User} from '../model/user.model';
import {Personne} from '../model/model.personne';
import {Platform} from '@ionic/angular';
import {Attributs} from '../model/attributes.model';
import {Context} from '../shared/services/context.service';
import {correspondant} from '../model/correspondant.model';
import {UserAuthentifie} from '../model/UserAuthentifie';
import {Contact} from 'src/app/model/Contact.model';
import {Patient} from '../model/model.patient';
import {SmallPersonne} from '../model/smallPersonne.model';
import {TplCorrepondant} from '../model/TplCorrespondantBaseLettre.model';
import { TypePersonne } from '../model/typepersonne.model';
import { SmallCorrespondant } from '../model/smallCorrespondant.page';
import { Searchcorr } from '../model/searchcorr.model';
import { MatLineSetter } from '@angular/material';
import { niveau } from '../model/niveau.model';
import { Observable, Subject } from 'rxjs';
import { lienCorrespondant } from '../model/lienCorrespondant.model';
import { categorie } from '../model/categorie.model';
import { DatePipe } from '@angular/common';



@Injectable({
    providedIn: 'root'
})
export class UserService {
    gabarit: any;
private subject = new Subject<any>();

  sendEvent(event: any) {
    this.subject.next(event);
  }


  getEvent(): Observable<any> {
    return this.subject.asObservable();
  }
    private _url : string = "src/assets/lettre.json" ;
    constructor(
        private http: HttpClient,
        public platform: Platform,
        private datePipe: DatePipe,
        private context: Context) {
    }


    getSmallByPage(page) {
        var req = this.http.get<User[]>(Context.apiUrl + 'getSmalByPage/' + page + '&100');
        return req;
    }

    

    

    getNombreCorrespMedical() {
        var req = this.http.get<number>(Context.apiUrl + 'nbSmalCorresp');
        return req;
    }

    

    getUsers() {
        return this.http.get<User[]>(Context.apiUrl + 'Utilisateurs');
    }

    searchEntries(term) {
        var req = this.http.get<User[]>(Context.apiUrl + 'patient/chercher?chaine=' + term);
        return req;
    }

    
  GetNextNumDossier() {
    return this.http.get<Personne>(Context.apiUrl + 'getMaxIdPersonne/' );
  }

  

  getSmall(term) {
    var req = this.http.get<User[]>(Context.apiUrl + 'getsmall/0&' + term);
    return req;
  }
  
 

    getUserById(id: number) {

        return this.http.get<Personne>(Context.apiUrl + 'personne/' + id);
    }

    updatePersonne(personne: any) {

        return this.http.put(Context.apiUrl + 'personne/update/' + personne.id_personne, personne);
    }

    deletePersonne(id: number) {
        return this.http.delete(Context.apiUrl + 'patient' + id);
    }

    detectPlatform() {
        return this.platform.is('desktop');
    }

    getAllAttributs() {
        return this.http.get<Attributs[]>(Context.apiUrl + 'Attributs');
    }

    getPatientEnAttente(date1, date2) {
        var req = this.http.get<User[]>(Context.apiUrl + 'PatientsInAttente/' + date1 + '&' + date2);
        return req;
    }

    getcorrespondantsByName() {
        var req = this.http.get<User[]>(Context.apiUrl + 'getcorrespondantsByName/INTERNET');
        return req;
    }

    getDentist(idPersonne) {
        var req = this.http.get<Personne[]>(Context.apiUrl + 'getDentist/' + idPersonne);
        return req;
    }

    getCorrespondant(id) {
        var req = this.http.get<correspondant>(Context.apiUrl + 'personne/getCorrespondant/' + id);
        return req;
    }

    addLienCorrespondant(lien) {

        return this.http.post(Context.apiUrl + 'lienPers/insertLienCorrespondant/', lien);
    }

    getCorrespondantByNames() {
        var req = this.http.get<correspondant[]>(Context.apiUrl + 'getcorrespondantsByName/INTERNET');
        return req;
    }
  getAllPersonne(){
    return this.http.get<Personne>(Context.apiUrl + 'personnes');

  }

 

    insertCorrespondant(correspondant) {
        return this.http.post(Context.apiUrl + 'correspondant/insert/', correspondant);
    }

    AjoutCorrespondant(correspondant) {
        return this.http.post(Context.apiUrl + 'ajoutCorrespondant/', correspondant);
    }

    getPersonneWithNumDossier(numDossier: number) {
        return this.http.get<Personne>(Context.apiUrl + 'getPersonneByNumDossier/' + numDossier);
    }

    updatePersonneQ(personne, id_personne: number) {

        return this.http.put(Context.apiUrl + 'personne/updatePersonneQ/' + id_personne, personne);
    }

    getAllPatientsV2(nom: string,
                     prenom: string,
                     email: string,
                     Tel: string,
                     ville: string,
                     cdPostal: string,
                     adresse: string,
                     praticien :string ) {
        return this.http.get<Personne[]>(Context.apiUrl + 'getAllPatientsV2/' + nom + '&' + prenom + '&' + email + '&' + Tel + '&' + ville + '&' + cdPostal + '&' + adresse + '&' +
            false + '&' + false + '&' + praticien);
    }

    getPatientNumDossier(idPersonne) {
        return this.http.get<number>(Context.apiUrl + 'patient/numDossier/' + idPersonne);
    }

    // Ajouter Les Users //
    // Ajouter Les Users //
    AddUser(userAuthentifie: UserAuthentifie) {
        console.log(userAuthentifie);
        return this.http.post(Context.apiUrl + 'AddUserCabinet', userAuthentifie);
    }

    updateUser(userAuthentifie: UserAuthentifie) {
        console.log(userAuthentifie);
        return this.http.post(Context.apiUrl + 'registerUserCabinet', userAuthentifie);
    }

    getPersonneAllInfo(id: number) {
        var req = this.http.get<Patient>(Context.apiUrl + 'personne/getAllCoordPatient/' + id);
        return req;
    }

    AddContact(contact: Contact) {
        return this.http.post(Context.apiUrl + 'addContact', contact);
    }
    UpdateContact(id : number, contact: Contact) {
        // console.log(contact);
        return this.http.put(Context.apiUrl + 'UpdateContact/' + id , contact);
    }

  AddContactt(contact) {
    console.log("service",contact);
    return this.http.post(Context.apiUrl + 'Insert/addContact' , contact);
}

    getCorrespandantById(id_personne) {
        return this.http.get<SmallPersonne[]>(Context.apiUrl + 'personne/getCorrespondant/' +id_personne);
    }



    getTop50Correspondants() {
        return this.http.get<SmallPersonne[]>(Context.apiUrl + 'getTop50Correspondants');
    }

    TplCorrespondantBaseLettre(id_personne: number) {
        return this.http.get<TplCorrepondant[]>(Context.apiUrl + 'getCorrespondantBaseLettre/' + id_personne);
    }

     searchSmallCorrespondant(search: string = '') {
        return this.http.get<SmallPersonne[]>(Context.apiUrl + 'searchSmallCorrespondant/' + search);
    }
    searchCorrespondantContact(search: string = '') {
        return this.http.get<SmallCorrespondant[]>(Context.apiUrl + 'searchCorrespondantContact/' + search);
    }
    GetTypeCorrespondants() {
        return this.http.get<TypePersonne[]>(Context.apiUrl + 'GetTypeCorrespondants');
    }

    getCorrespondantsParNom(nom : string=''){
        return this.http.get<SmallPersonne[]>(Context.apiUrl + 'getCorrespondantsParNom/' + nom);

    }
    getStaffs(){
        return this.http.get<SmallPersonne[]>(Context.apiUrl + 'getStaffs');
    }
    getPersonnesAContacter(id_personne){
        return this.http.get<TplCorrepondant[]>(Context.apiUrl + 'getPersonnesAContacter/' + id_personne );
    }
    today: Date = new Date();
    pipe = new DatePipe('en-US');
    todayWithPipe = null;
    
    rechercherCorresopndantFullParameters(categories : string,
         typeliste: string ,
         nom : string,
         prenom: string,
         Note : number ,
         sensNote : number ,
         region : string,
         ville: string ,
        //  dateDebut = new Date ("1950-01-01 00:00:00"),
        // dateFin : Date = new Date() ,
       //  dateFin = this.pipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss' ),
        // dateFin = this.datePipe.transform(this.today, 'dd/MM/yyyy'),
        // dateFin = new  Date ("2022-02-09 00:00:00"),
         categactuel : boolean = true){
        
        // console.log("sdqsd",dateFin)

            // return this.http.get<SmallPersonne[]>(Context.apiUrl + 'rechercheCorrespondantFullParameters/'  + categories +'&'+ typeliste + '/'+ nom + '&'+ prenom + '/' +   '&'  + '/'  + region + '&' + ville + '/' + dateDebut + '&' + dateFin + '/' + categactuel );
            return this.http.get<SmallPersonne[]>(Context.apiUrl + 'rechercheCorrespondantFullParameters/'  + categories +'&'+ typeliste + '/'+ nom + '&'+ prenom + '/' +   '&'  + '/'  + region + '&' + ville + '/' + "1950-01-01 00:00:00" + '&' + "2022-02-09 00:00:00" + '/' + categactuel );

        }


// rechercherCorrespondantFullParametersRefactored(categories : string,
//                                                 typeliste : string,
//                                                 nom : string , 
//                                                 prenom : string , 
//                                                 sensNote : number,
//                                                 Note : number,
//                                                 region : string , 
//                                                 ville : string ,
//                                                 dateDebut : Date , 
//                                                 dateFin : Date, 
//                                                 categactuel : boolean 
//     ){
//     return this.http.get<Searchcorr>(Context.apiUrl + 'rechercheCorrespondantFullParameters/'+ categories  + typeliste + nom + prenom + sensNote + Note + region + ville + dateDebut + dateFin + categactuel );
// }


    getTypePersonnes(){
        return this.http.get<TypePersonne[]>(Context.apiUrl + 'getTypePersonnes');
    }
   getEtage() : Observable<niveau[]>{
       return this.http.get<niveau[]>(Context.apiUrl +   this._url);
   }
   getCorrespondantsOfPatient(ID_PATIENT){
       return this.http.get<lienCorrespondant[]>(Context.apiUrl + 'getCorrespondantsOfPatient/' + ID_PATIENT)

   }

   InsertContact(contact: Contact) {
    // console.log(contact);
    return this.http.post(Context.apiUrl + '/Insert/addContact', contact);
}



}
