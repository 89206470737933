import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Context, InjectedContext } from 'src/app/shared/services/context.service';
import { ModalController, LoadingController, ActionSheetController, AlertController, Platform } from '@ionic/angular';
import { ObjetService } from 'src/app/service/objet.service';
import { UserService } from 'src/app/service/user.service';
import { LnkAttributsObjetsService } from 'src/app/service/lnkAttributsObjets.service';
import { GalleryViewerComponent } from '../gallery-viewer/gallery-viewer.component';
import { CustomToastService } from 'src/app/service/custom-toast.service';
import { toastTypeColor, toastTime } from '../../../model/types';
import { GabaritPropertyComponent } from '../gabarit-property/gabarit-property.component';
import { GabaritToolsComponent } from '../gabarit-tools/gabarit-tools.component';
import { isWindows } from 'src/app/app.component';

// import { FileTransfer, FileTransferObject } from '@ionic-native/file-transfer/ngx';
// import { File } from '@ionic-native/file/ngx';
// import { FileOpener } from '@ionic-native/file-opener/ngx';

@Component({
  selector: 'app-gallery-photo',
  templateUrl: './gallery-photo.component.html',
  styleUrls: ['./gallery-photo.component.scss'],
})
export class GalleryPhotoComponent implements OnInit, OnChanges, OnDestroy {
  @Output('selectionChange') valueChange = new EventEmitter<any>()
  @Output('setPhotoToVisionate') photoToVisionate = new EventEmitter<any>()
  @Output('getPhotoNumber') photoNumber = new EventEmitter<any>()
  @Output('getUserImage') userImageToSend = new EventEmitter<any>()
  @Output('getUserSelectedFromListePersonne') nomPrenom = new EventEmitter<any>()
  @Output('userImages')userImages = new EventEmitter<any>(); 
  @Input('reloadUser') reloadUser ;
  @Input('idPersonne') idPersonne;
  @Input('newImage') newImage;
  @Input('ListImageAdded')ListImageAdded ;
  @Input('editPhoto') editPhoto;
    public width  : number = 6; 
  
  @Output() Changevalue = new EventEmitter();
  selectedImages = [];
  public maxSelectionMsg : string = "Le nombre maximale de photos sélectionnées est 2";
  public pdfDocument : string = "Désolé c'est un document pdf";
  constructor(
    private context: Context,
    private modalCtrl: ModalController,
    private objetService: ObjetService,
    private loadingController: LoadingController,
    private userService: UserService,
    public actionSheetController: ActionSheetController,
    public alertController: AlertController,
    private lnkAttributObjetService: LnkAttributsObjetsService,
    private plateform :Platform,
    private customToastService: CustomToastService,

    // private transfer: FileTransfer,
    // private file: File  ,
    // private fileOpener: FileOpener,
  ) { }

  isTablette = this.context.detectPlatformTablette();
  sizeList = "";
  @Input('userImage') userImage = [];
  selectedUser = {};
  loading;
  imagesToVisionate: any;
  oldIdPersonne: number;
  objetFaceSourire = {};
  Image;
  userSelected = {};
  imageUrlPrefix = "";
  isDesktop: boolean;
  images = [];
  page = 0;
  maximumPages = 10;
  nbImage: number;
  maxPage = 0;
  nomGabarit : string;
  loadMore: number = 0;

  ngOnInit() {
    this.isDesktop = this.context.detectPlatformDesktop() || isWindows();
    document.addEventListener('contextmenu', this.contextMenuHandler);
  }

  ngOnDestroy(): void {
    document.removeEventListener('contextmenu', this.contextMenuHandler);
  }

  contextMenuHandler(event: MouseEvent) {
    event.preventDefault();
  }

  refreshImage(imgs: any[]): void {
    for (let i = imgs.length - 1; i >= 0; i--) {
        this.userImage.unshift(imgs[i]);
    }
}
  addNewPhotos(imgs){
    console.log("userImage",this.userImage);
    console.log("imgs",imgs);

    // imgs.forEach(element => {
    //   console.log("element",element);
    //   this.userImage.push(element);
    // });
  }


  async ngOnChanges(changes: SimpleChanges) {
    if(this.userImage.length == 0) {
      this.page = 0;
      if (this.idPersonne !== undefined && this.idPersonne !== null) {
        this.objetService.getPhotoNumber(this.idPersonne).subscribe(nb => {
          //console.log(nb);
          
          this.nbImage = nb;
          if (this.isDesktop || this.isTablette) {
            if (changes.newImage && changes.newImage.currentValue != changes.newImage.previousValue && changes.reloadUser.currentValue === undefined) {
              if (changes.newImage.currentValue['pkObjet']) {
                this.userImage.push(changes.newImage.currentValue);
                this.userImageToSend.emit(this.userImage);
                this.photoNumber.emit(this.nbImage);
              }
            } else {
              this.loadDataDesktopTablette(this.nbImage).then(value=>{
                
              });
            }
          } else {
            if(this.loadMore == 0) {
              this.loadMore++;
              this.loadImageByPage(this.nbImage).then(value=>{
                
                });
            }
            else {
              this.loadMore++;
              this.loadDataDesktopTablette(this.nbImage).then(value=>{
              });            
            }
  
          }
          
        });
      } else {
        this.userImage = [];
      }
    }

  }


  eventImageMouseup(event , picture){
     if(event['button']===2){

      this.presentActionSheet(picture);
     }
  }
  async presentActionSheet(picture) {
    this.Image = picture;
    const actionSheet = await this.actionSheetController.create({
      //   header: '',
      buttons: [
        {
          text: 'Ajouter gabarit',
          handler: () => {
            this.openGabaritOutils(picture);
          }
        },
        {
          text: 'Supprimer gabarit',
          handler: () => {
            this.deleteGabarit(picture);
          }
        },  {
          text: 'Ouvrir photo',

          handler: () => {
            this.getUserSelected()
          }
        }, {
          text: 'Supprimer photo',

          handler: () => {
            this.presentAlertConfirm(picture)
          }
        }, {
          text: 'Proprieté',

          handler: () => {
            this.opneAttribut(picture)
          }
        }]
    });
    await actionSheet.present();
  }


  deleteObjet(picture) {
    this.lnkAttributObjetService.deleteByObjet(picture.pkObjet).subscribe(result => {
    });
    this.objetService.deleteObjet(picture.pkObjet).subscribe(result => {
    });
    this.imageUrlPrefix =  "/" +Context.folder + this.idPersonne + "/" + this.Image["fichier"];
    this.objetService.findAndRmove(this.imageUrlPrefix).subscribe(res => {
    });

    this.userImage.splice(this.userImage.indexOf(picture), 1);
    this.nbImage -=1;
    this.photoNumber.emit(this.nbImage);
  }

  async presentAlertConfirm(picture) {
    const alert = await this.alertController.create({
      header: 'Supprimer Objet',
      message: 'Souhaitez-vous réellement <strong>supprimer</strong> cet objet ?',
      buttons: [
        {
          text: 'Non',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
          }
        }, {
          text: 'Oui',
          handler: () => {
            this.deleteObjet(picture);
          }
        }
      ]
    });
    await alert.present();
  }


  
  async getUserSelected() {
    this.imageUrlPrefix = Context.root_folder + Context.folder + this.idPersonne + "/" + this.Image["fichier"];
    if (this.isDesktop) {
      window.open(this.imageUrlPrefix);
    } else {
      if (this.Image["fichier"].toUpperCase().indexOf('.PDF') > -1) {

      } else {
        const modal = await this.modalCtrl.create({
          component: GalleryViewerComponent,
          componentProps: {
            'pictures': this.imageUrlPrefix,
            'fromUrl': "true",
          },
          cssClass :'my-custom-modal-css'
        });
        return modal.present();
      }
    }
  }
  

  async loadImageByPage(nbImage) {
    
    if (this.idPersonne !== undefined) {
      this.userService.getUserById(this.idPersonne)
        .subscribe((userObject) => {
          this.nomPrenom.emit(userObject["per_nom"] + " " + userObject["per_prenom"]);
        });
        this.userImage = [];
      this.objetService.listphotoByPage(this.idPersonne, this.page, 10).
        subscribe(res => {
          this.userImage = this.userImage.concat(res);
            this.userImageToSend.emit(this.userImage);
            this.photoNumber.emit(nbImage);
            this.imagesToVisionate = this.userImage.filter(image => this.selectedImages.indexOf(image.pkObjet) != -1);
            this.valueChange.emit(this.selectedImages);
            this.photoToVisionate.emit(this.imagesToVisionate);
          //pour vider la liste des photos selectionnées apres le changement du personne 
          this.selectedImages = [];
        });
    }
  }

  loadData(event) {
    let index = 1;
    if (this.nbImage % 10 === 0) {
      this.maxPage = this.nbImage / 10;
    } else {
      this.maxPage = this.nbImage / 10 - (this.nbImage % 10 / 10) + 1;
    }
    
    if (this.loadMore == 1) {
    if (this.maxPage === this.page ) {
      event.target.complete();
    }
    else {
      for (index = 1; index <= this.maxPage; index++) {
        this.page++;
        this.objetService.listphotoByPage(this.idPersonne, this.page, 10).
          subscribe(res => {
            this.userImage = this.userImage.concat(res);
          });
        event.target.complete();
        if (this.images.length == this.nbImage) {
          event.target.disabled = true;
         
        }
      }
    }
  } else {
    event.target.complete();
  }
  }
  async loadDataDesktopTablette(nbImage) {
    this.userService.getUserById(this.idPersonne)
      .subscribe((userObject) => {
        this.nomPrenom.emit(userObject["per_nom"] + " " + userObject["per_prenom"]);
      });
    this.userImage = [];
    if (this.idPersonne !== undefined && this.idPersonne !== null) {
      let index = 0;
      if (nbImage % 10 === 0) {
        this.maxPage = nbImage / 10 - 1;
      } else {
        this.maxPage = nbImage / 10 - (nbImage % 10 / 10);
      }
      this.maxPage = Math.round(this.maxPage);
      
      for (index = 0; index <= this.maxPage; index++) {
        
        await this.objetService.listphotoByPage(this.idPersonne, index, this.nbImage).
          subscribe(res => {
            //console.log(res);

            this.userImage = this.userImage.concat(res);
            this.userImageToSend.emit(this.userImage);
            this.photoNumber.emit(nbImage);
            this.imagesToVisionate = this.userImage.filter(image => this.selectedImages.indexOf(image.pkObjet) != -1);
            this.valueChange.emit(this.selectedImages);
            this.photoToVisionate.emit(this.imagesToVisionate);
            this.userImages.emit(this.userImage); 
            //pour vider la liste des photos selectionnées apres le changement du personne 
            this.selectedImages = [];
            InjectedContext.picturePatient = this.userImage;

          });
         
      }
    }
  }
  setPhotoInList(picture) {
      if(this.isDesktop) return;  
        
      if(picture.fichier.toUpperCase().indexOf('.PDF') > -1) {
        this.customToastService.presentToast(this.pdfDocument, toastTypeColor.red, toastTime.small);
        return;
      }
      const idPicture = picture.pkObjet;
      const imageIndexInArray = this.selectedImages.indexOf(idPicture)
      if (imageIndexInArray === -1) {
        if ((this.selectedImages.length < 2 && (!this.isTablette || !this.isDesktop) ) || (this.selectedImages.length < 4 && (this.isTablette || this.isDesktop))) {
          this.selectedImages.push(idPicture)
        }
        else if (this.selectedImages.length == 2 && !this.isTablette) {
          this.customToastService.presentToast(this.maxSelectionMsg, toastTypeColor.red, toastTime.small);
        }
      } else {
        this.selectedImages.splice(imageIndexInArray, 1);
      }
      this.imagesToVisionate = this.userImage.filter(image => this.selectedImages.indexOf(image.pkObjet) != -1)
      this.valueChange.emit(this.selectedImages);
      this.photoToVisionate.emit(this.imagesToVisionate);
  }
  clickPhotoList(picture){
    
    // if(picture.fichier.toUpperCase().indexOf('.PDF') > -1) {
    //   let url= Context.root_folder + Context.folder + this.idPersonne + '/' + picture.fichier;
    //   window.open(url); 
    //   // this.customToastService.presentToast(this.pdfDocument, toastTypeColor.red, toastTime.small);
    //   return;
    // }
    this.imagesToVisionate =picture; 
    this.photoToVisionate.emit(this.imagesToVisionate); 
  }
  async openGabaritOutils(picture) {
    const modal = await this.modalCtrl.create({
      component: GabaritToolsComponent,
      componentProps: {
        'objetSelected': picture
      },
      cssClass :'my-custom-modal-css-gabarit'
    });
    return modal.present();
  }

  async deleteGabarit(picture) {
    
    picture.idGabarit = 0 ;
    // console.log("picture",picture);
    this.lnkAttributObjetService.deleteByObjet(picture.pkObjet).subscribe(result => {
    });
    this.objetService.updateIdGabarit(picture).subscribe(result => {
    });
    
    
  }
  
  async opneAttribut(picture) {
    const modal = await this.modalCtrl.create({
      component: GabaritPropertyComponent,
      componentProps: {
        'objetSelected': picture,
      }
    });
    return modal.present();
  }

  async getObjetFaceSourire() {
    await this.objetService.getObjetFaceSourire(this.idPersonne)
      .subscribe((objetObject) => {
        this.objetFaceSourire = objetObject;

        if (objetObject === null) {
         // this.objetFaceSourireEmpty.emit(objetObject);
        } else {
          this.selectedImages.push(this.objetFaceSourire['pkObjet']);
        }
      });
  }

    getNomGabarit(id):string{      
      if(id === 0)
      {
        this.nomGabarit = '';
         return this.nomGabarit;
      }
      for (let i = 0; i < InjectedContext.GabaritOutils.length; i++) {
        if (InjectedContext.GabaritOutils[i]["id_gabarit_outil"] === id)
        {
        this.nomGabarit = InjectedContext.GabaritOutils[i]["nom"]
        if(this.nomGabarit === '')
        {
          
             this.nomGabarit = '';        
             return this.nomGabarit;
          
        }
        return this.nomGabarit
       
      }
  
    }
  }

  settingSupp() {
    let img = <HTMLImageElement>document.querySelector("#image");
    console.log("img", img)
    img.oncontextmenu = () => false;
  }

}
