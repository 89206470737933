import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {UserService} from '../../service/user.service';
import {UserAuthentifie} from 'src/app/model/UserAuthentifie';
import { CustomLoadingService } from 'src/app/service/custom-loading.service';
@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
    public currentUser: UserAuthentifie;
    constructor(private authService: AuthService,
         private router: Router,

        ) {
    }
    canActivate(route: ActivatedRouteSnapshot): boolean {
        var exp = route.data.name;
        var data;
    //    console.log('exppppp :'+ typeof exp);
        var value = localStorage.getItem("currentUser");
        this.currentUser = JSON.parse(value);
        data =  this.currentUser.authorities;
        
        if (localStorage.getItem("ACCESS_TOKEN") != null) {
            for (let i = 0; i < data.length; i++) {
                var x =JSON.stringify(this.currentUser.authorities[i]);
                var role = JSON.parse(x);
                //  console.log("x"+x);
                // console.log('exp :'+exp);
                // console.log('data :'+data);
                // console.log("role"+role.name);
                // if (typeof exp != 'undefined') {
                    // if(typeof exp === 'undefined'){
                    //     exp = {"":""}
                    // }
                    // var size = Object.keys(exp).length;
                        if (typeof exp === 'undefined') {
                            return this.authService.isLogged();
                        }
                        else {
                            for (let index = 0; index < exp.length; index++) {
                                if ((role.name == exp[index]) || (typeof exp[index] === 'undefined')) {
                                    // console.log('x :'+x);
                                    // console.log("Yes" + route.pathFromRoot+exp+role.name);
                                    return this.authService.isLogged();
                                }
                                
                            }
                        }

                    
                // }
                
                // if ((role.name == exp) || (typeof exp === 'undefined')) {
                //     // console.log('x :'+x);
                //     // console.log("Yes" + route.pathFromRoot+exp+role.name);
                //     return this.authService.isLogged();
                // }
            }
            // else {
                this.router.navigateByUrl('acces-denied');
                // console.log("No")
                // return false;
            // }
        }
        
    }
    
}