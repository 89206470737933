import { Component, OnInit, Input, OnChanges, ViewChild, ElementRef, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ImageTransform, ImageCroppedEvent } from 'ngx-image-cropper';
import { AlertController } from '@ionic/angular';
import { ImageServiceService } from 'src/app/service/image-service.service';
import domtoimage from 'dom-to-image';
import { objet } from 'src/app/model/objet.model';
import { CustomLoadingService } from 'src/app/service/custom-loading.service';

@Component({
  selector: 'app-image-processing',
  templateUrl: './image-processing.component.html',
  styleUrls: ['./image-processing.component.scss'],
})
export class ImageProcessingComponent implements OnInit, OnChanges {
  dataUrL64: string = "";
  @Output('image64') image64 = new EventEmitter<any>()
  imageUrlPrefix: string;
  @Input() idPersonne; //input
  @Input() Context; //input
  @Input() imagesToVisionate: objet[] = []; //input
  @Input() nbImages;
  @Input() parentComponent;
  @Input() lockedStatus:boolean[] = [false,false,false,false];
  @Input() sizeimagesToVisionate;
  @Input() domToImage;
  imageBase64: string = "";
  resetImage64: string = "";
  myThumbnail: string;
  myFullresImage: string;
  public thumbWidth: number;
  public thumbHeight: number;
  public fullWidth: number;
  public fullHeight: number;
  public lensWidth = 100;
  public lensHeight = 100;
  public imgSize : any ;
  showSpinner : boolean = false;

  public icons = [
    {
      id: 'crop',
      name: 'crop',
      src: null
    },
    {
      id: 'checkmark',
      name: 'checkmark',
      src: null
    },
    {
      id: 'left',
      name: null,
      src: 'data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBpZD0iQ2FwYV8xIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA0ODguNDcxIDQ4OC40NzEiIGhlaWdodD0iNTEycHgiIHZpZXdCb3g9IjAgMCA0ODguNDcxIDQ4OC40NzEiIHdpZHRoPSI1MTJweCI+PHBhdGggZD0ibTM2LjA3NSAyMjYuMjEzIDU5LjQ3OSAxMy44MzRjNS43NTQtMjQuNzkgMTcuODI5LTQ3Ljk4NiAzNC45MTItNjcuMDY2bC00NS40OTYtNDAuNzI2Yy0yMy45MTEgMjYuNzEzLTQwLjgxNSA1OS4yMS00OC44OTUgOTMuOTU4eiIgZmlsbD0iIzAwMDAwMCIvPjxwYXRoIGQ9Im0zMC41MyAzMDQuOTIxYzUuNjE4IDM5LjQ1OSAyMi4yMzIgNzYuNTE3IDQ4LjA2IDEwNy4xMzZsNDcuMDExLTM5LjIzNWMtMTguNDQyLTIxLjg4My0zMC4zMDgtNDguMzEzLTM0LjMwNy03Ni40NTggMCAuMDAxLTYwLjc2NCA4LjU1OC02MC43NjQgOC41NTd6IiBmaWxsPSIjMDAwMDAwIi8+PHBhdGggZD0ibTI3NC43NjUgNjMuNDk2di02My40OTZsLTkxLjU4OCA5MS41ODggOTEuNTg4IDkxLjU4OHYtNTcuOTczYzY5LjU4NiAxNC4xODYgMTIyLjExOCA3NS44NSAxMjIuMTE4IDE0OS41NjEgMCA4NC4xNjUtNjguNDgyIDE1Mi42NDctMTUyLjY0NyAxNTIuNjQ3LTI1LjYyNSAwLTUwLjk1Mi02LjQ1NS03My4yMjMtMTguNjc4bC0yOS4zNjcgNTMuNTQ2YzMxLjI2IDE3LjEyOCA2Ni43MzkgMjYuMTkxIDEwMi41OSAyNi4xOTEgMTE3LjgzOSAwIDIxMy43MDYtOTUuODgxIDIxMy43MDYtMjEzLjcwNi0uMDAxLTEwNy40MzMtNzkuNzg2LTE5Ni4zNzQtMTgzLjE3Ny0yMTEuMjY4eiIgZmlsbD0iIzAwMDAwMCIvPjwvc3ZnPgo=',
    },
    {
      id: 'right',
      name: null,
      src: 'data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDQwMy44NCA0MDMuODQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQwMy44NCA0MDMuODQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiPgo8Zz4KCTxnPgoJCTxnPgoJCQk8cGF0aCBkPSJNMzcxLjg0LDIxMy4zMzNjLTMuNjI3LTI5LjY1My0xNS40NjctNTguMjQtMzQuNjY3LTgyLjk4N2wtMzAuMTg3LDMwLjE4N2MxMS40MTMsMTYuMTA3LDE4LjY2NywzNC4xMzMsMjEuNzYsNTIuOCAgICAgSDM3MS44NHoiIGZpbGw9IiMwMDAwMDAiLz4KCQkJPHBhdGggZD0iTTI3OC40LDk3LjA2N0wxODEuMzMzLDB2NjUuNDkzQzk3LjE3Myw3NS45NDcsMzIsMTQ3LjYyNywzMiwyMzQuNjY3czY1LjE3MywxNTguNzIsMTQ5LjMzMywxNjkuMTczdi00My4wOTMgICAgIGMtNjAuNDgtMTAuMTMzLTEwNi42NjctNjIuNzItMTA2LjY2Ny0xMjYuMDhzNDYuMTg3LTExNS45NDcsMTA2LjY2Ny0xMjYuMDhWMTkyTDI3OC40LDk3LjA2N3oiIGZpbGw9IiMwMDAwMDAiLz4KCQkJPHBhdGggZD0iTTIyNCwzNjAuNjR2NDMuMmMyOS42NTMtMy42MjcsNTguNDUzLTE1LjE0Nyw4My4yLTM0LjM0N2wtMzAuNjEzLTMwLjYxM0MyNjAuNTg3LDM1MC4xODcsMjQyLjY2NywzNTcuNDQsMjI0LDM2MC42NHoiIGZpbGw9IiMwMDAwMDAiLz4KCQkJPHBhdGggZD0iTTMwNi45ODcsMzA4LjhMMzA2Ljk4NywzMDguOGwzMC4xODcsMzAuMTg3YzE5LjItMjQuNzQ3LDMxLjA0LTUzLjMzMywzNC42NjctODIuOTg3aC00My4wOTMgICAgIEMzMjUuNjUzLDI3NC42NjcsMzE4LjQsMjkyLjY5MywzMDYuOTg3LDMwOC44eiIgZmlsbD0iIzAwMDAwMCIvPgoJCTwvZz4KCTwvZz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K'
    },
  ];
  public buttons = [
    {
      title: 'zoom+'
    },
    {
      title: 'zoom-'
    },
    {
      title: 'reset'
    },
    {
      title: 'mirror horizontal'
    },
    {
      title: 'mirror vertical'
    }
  ];
  public alertMsg: string = "Vous devez sélectionner une image";
  public badPhoto: string = "une erreur s'est produite lors du telechargement de la photo";
  croppedImage: any = '';
  imgRotation: boolean;
  async ngOnChanges(changes: SimpleChanges) {
    await this.getUserSelected();
    if (this.nbImages == '1' && this.imagesToVisionate.length > 0) {
      console.log(this.imagesToVisionate);
      let url= this.imageUrlPrefix + '/' + this.imagesToVisionate[0].fichier;
      
      this.customLoadingService.presentLoading().then(async value => {
        this.getBase64Image().then(async (res) => {
          this.canvasRotation = 0;
          this.rotation = 0;
          this.scale = 1;
          this.showCropper = res;
          this.containWithinAspectRatio = false;
          this.transform = {};
          // await this.setcanvasvalue(canvas,res)
          
        })
      });
    }

    
    if (this.domToImage == true) {
      if (this.imagesToVisionate.length == 0) {
        this.presentAlert(this.alertMsg);
        this.domToImage = false;
        return;
      } else {
        this.imageto64().then(() => {

        });
      }
    }
  }

 

  constructor(
    public alertController: AlertController,
    private imageService: ImageServiceService,
    private customLoadingService: CustomLoadingService
  ) { }
  async ngOnInit() {
    
    if (this.idPersonne == undefined) {
      this.idPersonne = localStorage.getItem("idPatient");
    }
    this.showCropper = false;
  }

  ImgGetWidth(src){
    return new Promise((resolve, reject) => {
      let img = new Image()
      img.onload = () => resolve(img.width)
      img.onerror = reject
      img.src = src
    })
  }

  ImgGetHeight(src){
    return new Promise((resolve, reject) => {
      let img = new Image()
      img.onload = () => resolve(img.height)
      img.onerror = reject
      img.src = src
    })
  }

// async verifImgRotation(img){
// let url = this.imageUrlPrefix + '/' + img;
// console.log(url);

//   let width :any;
//   let height :any;
// this.imgRotation = false
//   await this.ImgGetWidth(url).then(w => {
//     console.log("width",w)
//     width = w
//   })

//   await this.ImgGetHeight(url).then(h => {
//     console.log("height",h)
//     height = h
//     if (width < h) {
//       this.imgRotation = true

    
//       }
      
//       })
// }
  
onChangeRotationInput(event) {
  console.log("event",event);
  console.log(" this.rotation", this.rotation);
  this.rotation = event.target.value;    
  
  this.transform = {
    ...this.transform,
    rotate: this.rotation
  };
  this.rotation = event.target.value;    

}

  clickIcon(iconId) {
    switch (iconId) {
      case "checkmark":
        this.showSpinner = true;
        this.saveImageCropped();
        break;
      case "crop":
        this.crop();
        break;
      case "left":
        this.rotateLeft();
        break;
      case "right":
        this.rotateRight();
        break;
    }
  }
  clickBtn(btnTitle) {
    switch (btnTitle) {
      case "zoom+":
        this.zoomIn();
        break;
      case "zoom-":
        this.zoomOut();
        break;
      case "reset":
        this.Reinitialiser();
        break;
      case "mirror horizontal":
        this.flipHorizontal();
        break;
      case "mirror vertical":
        this.flipVertical();
        break;
    }
  }
  convertImageToBase64(url: string, callback) {

   
    
    var xhr = new XMLHttpRequest();


    xhr.onload = () => {

      var reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
      
    };
    xhr.open('GET', url);
    xhr.onerror = () => {
      this.customLoadingService.dismissLoading();
      this.presentAlert(this.badPhoto);
      
    }
    xhr.responseType = 'blob';
    xhr.send();


  }
  async getBase64Image(): Promise<any> {
    return new Promise(async resolve => {
      await this.convertImageToBase64
        (this.imageUrlPrefix + '/' + this.imagesToVisionate[0].fichier, (dataUrl) => {
          this.imageBase64 = dataUrl;
          this.customLoadingService.dismissLoading();
          resolve(true);
        })
    })

  }
  async saveImageCropped() {
    if (this.imagesToVisionate.length === 0) {
      this.presentAlert(this.alertMsg);
      return
    }
    this.imageService.imageToSaveOrUpdate(this.croppedImage, this.imagesToVisionate[0], this.idPersonne, "update")
    .then(res => {
      this.showSpinner = false;
      // Handle the result 'res' here if needed
    })
    .catch(error => {
      // Handle any errors here
    });
  
  }

  crop() {
    if (this.imagesToVisionate.length == 0) {
      this.presentAlert(this.alertMsg)
    } else {
      this.deleteIconOfCroper();
      this.resetImage64 = this.imageBase64;
      this.imageBase64 = this.croppedImage;
    }

  }
  async presentAlert(msgAlert: string) {
    const alert = await this.alertController.create({
      header: 'Attention !',
      message: msgAlert,
      buttons: ['OK'],
      cssClass: 'my-custom-alert',
    });
    await alert.present();
  }
  Reinitialiser() {
    if (this.resetImage64 != "") {
      this.imageBase64 = this.resetImage64;
    } else {
      this.presentAlert("Vous devez faire des modifications sur  l'image avant de la reinitialiser");
    }
  }
  fileChangeEvent(event: any): void {
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  photoDisplayed : boolean = false;
  imageLoaded() {
    // show cropper
    this.photoDisplayed = true;
  }
  cropperReady() {
    this.photoDisplayed = false;
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  base64Captured;
  getCanvas() {

    var node = document.getElementById('canvas');
    var that = this;
    domtoimage.toPng(node, { quality: 0.70 })
      .then(function (dataUrl) {
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  }

  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};

  rotateLeft() {
    if (this.imagesToVisionate.length === 0) {
      this.presentAlert(this.alertMsg);
      return
    }
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    if (this.imagesToVisionate.length === 0) {
      this.presentAlert(this.alertMsg);
      return
    }
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }


  flipHorizontal() {
    if (this.imagesToVisionate.length === 0) {
      this.presentAlert(this.alertMsg);
      return
    }
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical() {
    if (this.imagesToVisionate.length === 0) {
      this.presentAlert(this.alertMsg);
      return
    }
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    if (this.imagesToVisionate.length === 0) {
      this.presentAlert(this.alertMsg);
      return
    }
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    if (this.imagesToVisionate.length === 0) {
      this.presentAlert(this.alertMsg);
      return
    }
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation
    };
  }

  getUserSelected() {
    this.imageUrlPrefix = this.imageService.getUserSelected(this.idPersonne);

  }
  async imageto64() {
    if (this.nbImages == '1') { this.deleteIconOfCroper(); }
    this.getimageUrlToimage64("imgId" + this.nbImages).then((res) => {
      this.image64.emit(this.dataUrL64);
    }).catch(err => {

    })



  }

  deleteIconOfCroper() {
    try {
      document.getElementsByClassName('container1').item(0).childNodes.item(0)
        .childNodes.item(0).childNodes.item(4).remove();

    } catch{ }




  }
  async getimageUrlToimage64(node: string) {
    //imgId1
    return new Promise(async (resolve, reject) => {
      await domtoimage.toPng(document.getElementById(node), { quality: 0.95 })
        .then(dataUrl => {

          this.dataUrL64 = dataUrl;
          resolve(dataUrl)
        })
        .catch(async err => {


          reject(err);
        })


    })
  }


  toggleLock(index: number) {
    // Toggle the locked status of the image at the specified index
    this.lockedStatus[index] = !this.lockedStatus[index];
  
  }

}
