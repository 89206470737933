import {Component, OnInit, AfterViewInit, ElementRef} from '@angular/core';
import {Platform, ModalController, NavController, PopoverController, LoadingController} from '@ionic/angular';
import {CameraPreview, CameraPreviewPictureOptions, CameraPreviewDimensions} from '@ionic-native/camera-preview/ngx';
import {Router} from '@angular/router';
import {Context, InjectedContext} from 'src/app/shared/services/context.service';
import {ImageViewerComponent} from '../views-photos/image-viewer/image-viewer.component';
import {CameraService} from 'src/app/service/CameraService';
import {MenuFlashPage} from 'src/app/popover/menu-flash/menu-flash.page';
import 'hammerjs';
import {environment} from 'src/environments/environment';
import {ObjetService} from 'src/app/service/objet.service';
import {PhotosListPage} from 'src/app/pages/photos-list/photos-list.page';


@Component({
    selector: 'app-camera',
    templateUrl: './camera.component.html',
    styleUrls: ['./camera.component.scss'],
})

export class CameraComponent implements OnInit, AfterViewInit {


    options: any;
    public img: '';
    gabaritOutils = [];
    selectedImage;
    // capturedImages = [];
    capturedImage = '';
    picture: any;
    idPersonne = '';
    slideOpts = {
        slidesPerView: '3.75',
        spaceBetween: 15

    };
    IdUserSelected;

    constructor(
        private context: Context,
        private cameraPreview: CameraPreview,
        private cameraService: CameraService,
        private router: Router,
        public modalCtrl: ModalController,
        public navCtrl: NavController,
        private platform: Platform,
        private popOver: PopoverController,
        private loadingController: LoadingController,
        private objetService: ObjetService
    ) {

        this.idPersonne = localStorage.getItem('idPersonne');
        // Context.getGabaritOutils();
        this.loadGabarit(async () => {

        });


    }


    isTablette = this.context.detectPlatformAndroid();

    ngAfterViewInit() {

    }

    maxWidth = 0;
    maxheight = 0;

    async setpreviewSize() {
        var that = this;
        this.cameraPreview.getSupportedPictureSizes().then((dimensions) => {

                dimensions.forEach(function (dimension) {
                    if (dimension.width > that.maxWidth) {
                        that.maxWidth = dimension.width;
                        that.maxheight = dimension.height;
                    }
                });

            },
            (err) => {
                alert(err);
            });
        // const cameraPreviewDimensions: CameraPreviewDimensions = {
        //   width: this.maxWidth,
        //   height: this.maxheight,
        // }
        // this.cameraPreview.setPreviewSize(cameraPreviewDimensions);
    }

    zoomWithVolumeButtons() {
        var that = this;
        document.addEventListener('volumedownbutton', function () {
            var oldZoom;
            that.cameraPreview.getZoom().then(value => {
                oldZoom = value;
                if (oldZoom > 6) {
                    oldZoom = oldZoom - 1;
                }
                that.cameraPreview.setZoom(oldZoom).catch(err => {
                    that.cameraService.setZoom(1);
                });
            });
        });
        document.addEventListener('volumeupbutton', function () {
            var oldZoom;
            that.cameraPreview.getZoom().then(value => {
                oldZoom = value;
                if (oldZoom < that.MAX_ZOOM) {
                    oldZoom = oldZoom + 1;
                }

                that.cameraPreview.setZoom(oldZoom).catch(err => {
                    that.cameraService.setZoom(1);
                });
            });


        });
    }

    oldinScal = 0;
    oldOutScal = 0;
    MAX_ZOOM = 40;

    zoomWithFingers() {
        var that = this;
        let element2 = document.getElementsByTagName('body')[0];

        let hamming = new Hammer.Manager(element2, {
            recognizers: [
                // RecognizerClass, [options], [recognizeWith, ...], [requireFailure, ...]
                [Hammer.Rotate],
                [Hammer.Pinch, {enable: true}, ['rotate']],
                [Hammer.Swipe, {direction: Hammer.DIRECTION_HORIZONTAL}],
            ]
        });
        hamming.on('pinch', function (ev) {
            // that.capturedZoom.push(ev.scale);
        });
        hamming.on('pinchend', function (ev) {
            // that.capturedZoom.push(ev.scale);


        });
        hamming.on('pinchmove', function (ev) {
            // that.capturedZoom.push(ev.scale);
        });
        hamming.on('pinchin', function (ev) {
            let oldZoom: number;
            that.cameraPreview.getZoom().then(value => {
                oldZoom = value;
                if (oldZoom > 1 && (that.IZoom - oldZoom) > 4) {
                    that.IZoom = oldZoom;
                    oldZoom = oldZoom - 1;
                    that.capturedZoom.push(oldZoom);
                }
                that.IZoom = that.IZoom + 1;
                that.cameraPreview.setZoom(oldZoom).then(zoom => {
                    that.oldinScal = ev.scale;
                }).catch(err => {
                    that.cameraPreview.setZoom(1);
                });
            });
        });


        hamming.on('pinchout', function (ev) {

            let oldZoom: number;
            that.cameraPreview.getZoom().then(value => {
                oldZoom = value;
                if (oldZoom < that.MAX_ZOOM && (that.Ozoom - oldZoom) > 4) {
                    that.Ozoom = oldZoom;
                    oldZoom = oldZoom + 1;
                }
                that.Ozoom = that.Ozoom + 1;
                that.cameraPreview.setZoom(oldZoom).then(zoom => {
                    that.oldOutScal = ev.scale;
                }).catch(err => {
                    that.cameraPreview.setZoom(1);
                });
            });
        });
    }

    IZoom = 0;
    Ozoom = 0;
    capturedZoom = [];

    closeCameraOnBackButton() {
        var that = this;
        document.addEventListener('backbutton', function () {
            that.capturedImages.clear();
            that.cameraService.close();
            that.loadUserMobile(that.idPersonne);
        });
    }

    focus(event) {
        this.cameraService.focus(event);
    }

    ngOnInit() {
        var that = this;
        //   document.getElementsByTagName('app-camera')[0].addEventListener("click", function(event){

        //     that.focus(event);
        // });
        this.objetService.createDossierPatient('/' + environment.folder + this.idPersonne).subscribe(value => {
        });
        this.gabaritOutils = InjectedContext.GabaritOutils.filter(g => g.imageCamera != null);
        //this.zoomWithVolumeButtons();
        this.zoomWithFingers();
        this.closeCameraOnBackButton();
        this.lockScreen();

    }

    async lockScreen() {
        if (this.platform.is('cordova')) {

            this.platform.ready().then(() => {
                window.screen.orientation.lock('portrait-primary');

            });
        }
    }

    ///dent-face.png
    async loadGabarit(cb?) {

    }

    reverseCamera() {
        this.cameraService.reverseCamera();
    }


    generateFromImage(img, MAX_WIDTH: number = 700, MAX_HEIGHT: number = 700, quality: number = 1, callback) {
        var canvas: any = document.createElement('canvas');
        var image = new Image();
        image.onload = () => {
            var width = image.width;
            var height = image.height;
            ;

            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                }
            } else {
                if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                }
            }
            canvas.width = width;
            canvas.height = height;
            var ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0, width, height);

            // IMPORTANT: 'jpeg' NOT 'jpg'
            var dataUrl = canvas.toDataURL('image/jpeg', quality);
            callback(dataUrl);
        };
        image.onerror = () => {
        };
        image.src = 'data:image/jpeg;base64,' + img;

    }

    createThumbnail(base64) {
        this.generateFromImage(base64, 700, 700, 1, data => {
            this.picturesThumbnail.set(base64, data);
            InjectedContext.localPictures = this.picturesThumbnail;
            this.capturedImage = data;
        }), error => {
            alert(error);
        };
    }

    capturedImages = new Map();

    picturesThumbnail = new Map();

    async takePicture() {


        const pictureOpts: CameraPreviewPictureOptions = {
            width: this.cameraService.maxWidth,
            height: this.cameraService.maxheight,
            quality: 100,
        };
        return this.cameraPreview.takePicture(pictureOpts).then((imageData) => {
            let mapNewImage = new Map();
            this.createThumbnail(imageData);
            //this.imageCompressor(imageData);
            mapNewImage.set(imageData, this.selectedImage);
            InjectedContext.pictures = mapNewImage;

        });
        // });


    }

    setFlash() {
        var flashMode = localStorage.getItem('flashMode');
        this.cameraPreview.setFlashMode(flashMode).then(data => {
        }).catch(err => {
            this.cameraPreview.setFlashMode('off');
        });

    }

    async openPopOver(ev: Event) {

        const popOv = await this.popOver.create({
            component: MenuFlashPage,
            translucent: true,
            componentProps: {},
            event: ev
        });
        popOv.present();
        popOv.onDidDismiss().then(data => {
            this.setFlash();
        }, (err) => {
            alert(err);
        });
    }

    changeImagePreview(image) {
        this.selectedImage = image;

    }

    changeBackgroundImgt(imagePath) {
        this.img = imagePath;
    }

    async loadUserMobile(idPersonne) {
        this.isTablette = this.context.detectPlatformTablette();
        if (this.isTablette) {
            //this.router.navigateByUrl('/home');
            this.router.navigateByUrl('/home', {skipLocationChange: true}).then(() =>
                this.router.navigate(['/camera']));
        } else {
            const modal = await this.modalCtrl.create({
                component: PhotosListPage,
                componentProps: {
                    'idPersonne': idPersonne,
                }
            });
            this.modalCtrl.dismiss();

            return modal.present();
        }
    }

    closeCamera() {

        this.capturedImages.clear();
        this.cameraService.close();
        this.loadUserMobile(this.idPersonne);
        this.modalCtrl.dismiss();
    }

    loading;

    async openPicture() {
        this.loading = await this.loadingController.create({
            message: 'Chargement...',
            spinner: 'crescent',
            duration: 2000
        });
        await this.loading.present();
        const modal = await this.modalCtrl.create({
            component: ImageViewerComponent,
            componentProps: {
                //   'newPictures': this.capturedImages,
                'idUser': this.IdUserSelected
            }
        });
        modal.onDidDismiss()
            .then((data) => {
                this.capturedImages.clear();
                this.capturedImage = '';
                this.picturesThumbnail.clear();
            });
        this.loading.dismiss();

        modal.present().then(value => {
        });

    }
}
